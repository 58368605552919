import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { UserLoginPojo } from "app/login/user-login/user-login.pojo";
import { UserMasterPojo } from "app/management/usermaster/usermaster.pojo";
import { IdProofTypePojo } from "app/user/excess/required.pojo";
import { StaticVariable } from 'app/globle.class';

@Injectable()

export class UserLoginService {

    constructor(private httpclient: HttpClient) { }

    checkLogin(userLogin: UserLoginPojo): Observable<any> {
        const url = StaticVariable.porturl + 'Login';
        return this.httpclient.post(url, userLogin);
    }

    userRegister(user: UserMasterPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddDevoteeUserMaster';
        return this.httpclient.post(url, user);
    }

    checkUserName(userName: string): Observable<any> {
        const url = StaticVariable.porturl + 'CheckUserName/' + userName;
        return this.httpclient.post(url, null);
    }

    updatePassword(login: UserLoginPojo): Observable<any> {
        const url = StaticVariable.porturl + 'PasswordUpdate';
        return this.httpclient.put<any>(url, login);
    }

    getAllIdProofType(): Observable<IdProofTypePojo[]> {
        const url = StaticVariable.porturl + 'IdProofTypeAll';
        return this.httpclient.get<IdProofTypePojo[]>(url);
    }

}
